import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Checkbox,
} from '@material-ui/core/';
//import InfoIcon from '@material-ui/icons/Info';

class CategorySelector extends Component {
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){
        return(
            <div>
                {this.props.name}
            </div>
        )
    }
}

export default CategorySelector