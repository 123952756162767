import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'

var api_link = 'https://zqbodvu635.execute-api.us-east-1.amazonaws.com/gift_api_alpha'

export const changeAmount =(new_amt)=>{
    return dispatch=>{
        dispatch(changingItem({
            amount:new_amt
        }))
    }
}
const changingItem = amtItem => ({
    type:'CHANGING_AMOUNT',
    payload:amtItem
})

export const scrollItem = (idx_key, gift_idx, forward)=>{
    let new_cat_idx_list = Array.from(store.getState().cat_idx_list)
    if(forward){
        new_cat_idx_list.splice(idx_key,1,gift_idx+1)
    }
    else{
        new_cat_idx_list.splice(idx_key,1,gift_idx-1)
    }
    return dispatch=>{
        dispatch(scrollingItem({
            cat_idx_list:new_cat_idx_list
        }))
    }
}

const scrollingItem = scrollItem => ({
    type:'SCROLL',
    payload:scrollItem
})

export const changeCategory = (category,add) =>{
    let new_cat_idx_list = Array.from(store.getState().cat_idx_list)
    let new_cat_shell = {}
    let new_cat_incl = Object.assign(new_cat_shell,store.getState().cat_incl)
    let new_cat_incl_list = Array.from(store.getState().cat_incl_list)
    return dispatch=>{

    if(add){
        //add to dict
        new_cat_idx_list.push(0)
        new_cat_incl[category] = true
        new_cat_incl_list.push(category)
        dispatch(changingCategory({
            cat_incl:new_cat_incl,
            cat_idx_list:new_cat_idx_list,
            cat_incl_list: new_cat_incl_list
        }))
    }else{
        //remove to dict 
        var idx_key = store.getState().cat_incl_list.indexOf(category)
        new_cat_idx_list.splice(idx_key,1)
        new_cat_incl_list.splice(idx_key,1)
        new_cat_incl[category] = false
        let new_response= Array.from(store.getState().response)
        new_response.splice(idx_key,1)
        dispatch(removingCategory({
            cat_incl:new_cat_incl,
            cat_idx_list:new_cat_idx_list,
            cat_incl_list: new_cat_incl_list,
            response:new_response
        }))
    }

        if(add){
            let rem_dict = {}
            rem_dict[category] = []
            //getSingleReponse(category,new_cat_incl.length-1, store.getState().amount,rem_dict,true,true)
                axios.post(api_link,
                    {
                    headers:{
                        "Access-Control-Allow-Origin":"*",
                        "Access-Control-Allow-Methods":["POST","OPTIONS"],
                        "Access-Control-Allow-Headers": ["Content-Type"],
                        "Content-Type":"application/json"
                    },
                    crossDomain:true,
                    params:{
                        category_list:[category],
                        base_price: store.getState().amount,
                        min:'true',
                        rem_dict: rem_dict,
                        get_single: 'true'
                    }
                })
                .then(function(data){

                    let results = JSON.parse(data['data']['body'])
                    // 'results' should be a single-item list
                    var items_list = JSON.parse(results[0])
                    let new_response = Array.from(store.getState().response)
                    new_response.push(items_list)
                    if(store.getState().cat_incl[category]){
                        dispatch(addingResults({
                            response:new_response
                        }))
                    }
                })
                .catch(function(error){
                    console.log(error)
                })
                .finally(function(){
                    console.log("finished!")
                }  )
        }
        // Send away for single Response
    }
}
const changingCategory = catItem => ({
    type:'CATEGORIES_CHANGE',
    payload:catItem
})

const removingCategory= catItem => ({
    type:'CATEGORIES_REMOVE',
    payload:catItem
})

export const getAdditionalGifts = (category, idx_key)=>{
    let curr_gift_list = store.getState().response[idx_key]
    let rem_dict_list = []
    curr_gift_list.map((item,idx)=>{
        if(item['id_list']){
            rem_dict_list = rem_dict_list.concat(item['id_list'])
        }
    })
    let rem_dict = {}
    rem_dict[category] = rem_dict_list
    return dispatch =>{
    // can't really dispatch loading flag if using a global flag

    axios.post(api_link,
        {
        headers:{
            "Access-Control-Allow-Origin":"*",
            "Access-Control-Allow-Methods":["POST","OPTIONS"],
            "Access-Control-Allow-Headers": ["Content-Type"],
            "Content-Type":"application/json"
        },
        crossDomain:true,
        params:{
            category_list:[category],
            base_price: store.getState().amount,
            min:'true',
            rem_dict: rem_dict,
            get_single: 'false'
        }
    })
    .then(function(data){
        // formatting response
        let results = JSON.parse(data['data']['body'])
        var items_list = JSON.parse(results[0])
        let new_response = Array.from(store.getState().response)
        let curr_gift = Array.from(store.getState().response[idx_key])
        let new_curr_gift= curr_gift.concat(items_list)
        new_response.splice(idx_key,1,new_curr_gift)
        // cat index list
        let new_cat_idx_list = Array.from(store.getState().cat_idx_list)
        new_cat_idx_list.splice(idx_key,1,curr_gift.length)
        // should only add if cat_incl is true.
            dispatch(addingGifts({
                cat_idx_list:new_cat_idx_list,
                response:new_response
            }))
    })
    .catch(function(error){
        console.log(error)
    })
    .finally(function(){
        console.log("finished!")
    }  )
    }
}

const addingGifts = resItem => ({
    type:'ADD_GIFTS',
    payload:resItem
})

export const getSingleReponse = (category,idx_key,price,rem_dict,get_single, append) =>{

        axios.post(api_link,
            {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods":["POST","OPTIONS"],
                "Access-Control-Allow-Headers": ["Content-Type"],
                "Content-Type":"application/json"
            },
            crossDomain:true,
            params:{
                category_list:[category],
                base_price: price,
                min:'true',
                rem_dict: rem_dict,
                get_single: get_single
            }
        })
        .then(function(data){
            console.log(data)
            let results = JSON.parse(data['data']['body'])
            // 'results' should be a single-item list
            var items_list = JSON.parse(results[0])
            let new_response = Array.from(store.getState().response)
            if(append){
                new_response.push(items_list)
            }else{
                let curr_gift = Array.from(store.getState().response[idx_key])
                let new_curr_gift= curr_gift.concat(items_list)
                new_response.splice(idx_key,1,new_curr_gift)
            }
            // should only add if cat_incl is true.
            
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(){
            console.log("finished!")
        }  )

}

export const getFullResponse = ()=>{
    //let cat_list
    return dispatch=>{
        dispatch(setLoading({
            loading_flg:true
        }))

        let cat_list = store.getState().cat_incl_list
        let amount = store.getState().amount
        let rem_dict = {}
        cat_list.map((cat,idx)=>{
            rem_dict[cat] = []
        })
        axios.post(api_link,
            {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods":["POST","OPTIONS"],
                "Access-Control-Allow-Headers": ["Content-Type"],
                "Content-Type":"application/json"
            },
            crossDomain:true,
            params:{
                category_list:cat_list,
                base_price: amount,
                min:'true',
                rem_dict: rem_dict,
                get_single: 'true'
            }
        })
        .then(function(data){
            let results = JSON.parse(data['data']['body'])
            // 'results' should be a single-item list
            let new_response = Array.from(cat_list) // needs to be an array the same size as cat incl list
            let new_cat_idx_list = []
            results.map((result, idx)=>{
                var items_list = JSON.parse(result)
                var cat = items_list[0]['category']
                var new_idx = cat_list.indexOf(cat)
                new_response.splice(new_idx,1,items_list)
                new_cat_idx_list.push(0)
            })
            dispatch(addingGifts({
                response:new_response,
                cat_idx_list:new_cat_idx_list
            }))
            
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(){
            console.log("finished!")
        }  )
 

    }
}

const setLoading = resItem => ({
    type:'IS_LOADING',
    payload:resItem
})

const addingResults = resItem => ({
    type:'NEW_RESULTS',
    payload:resItem
})

export const changeGroup = (groupName)=>{

    // change cat_incl_list, cat_incl_dict,
    let cat_list = store.getState().group_lists_dict[groupName]
    let all_gifts = store.getState().categories
    let new_cat_idx_list = []
    let new_cat_shell = {}
    let new_cat_incl = Object.assign(new_cat_shell,store.getState().cat_incl)
    let amount = store.getState().amount
    let rem_dict = {}
    all_gifts.map((cat, idx)=>{
        if(cat_list.includes(cat)){
            new_cat_incl[cat] = true
            new_cat_idx_list.push(0)
            rem_dict[cat]=[]
        }
        else{
            new_cat_incl[cat] = false
        }
    })
    return dispatch=>{
        dispatch(setLoading({
            loading_flg:true
        }))
        dispatch(changingCategory({
            cat_incl:new_cat_incl,
            cat_idx_list:new_cat_idx_list,
            cat_incl_list: cat_list
        }))
        axios.post(api_link,
            {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods":["POST","OPTIONS"],
                "Access-Control-Allow-Headers": ["Content-Type"],
                "Content-Type":"application/json"
            },
            crossDomain:true,
            params:{
                category_list:cat_list,
                base_price: amount,
                min:'true',
                rem_dict: rem_dict,
                get_single: 'true'
            }
        })
        .then(function(data){
            let results = JSON.parse(data['data']['body'])
            // 'results' should be a single-item list
            let new_response = Array.from(cat_list) // needs to be an array the same size as cat incl list
            let new_cat_idx_list = []
            results.map((result, idx)=>{
                var items_list = JSON.parse(result)
                var cat = items_list[0]['category']
                var new_idx = cat_list.indexOf(cat)
                new_response.splice(new_idx,1,items_list)
                new_cat_idx_list.push(0)
            })
            dispatch(addingGifts({
                response:new_response,
                cat_idx_list:new_cat_idx_list
            }))
            
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(){
            console.log("finished!")
        }  )
    }
}