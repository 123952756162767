import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Paper,
    Typography,
    Tooltip,
    CircularProgress
} from '@material-ui/core/';
import {Rating, ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

class GiftBox extends Component{
    
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){
        // Or show loading spinner
        if(this.props.loading_flg){
            return(<CircularProgress/>)
        }else{

        // Two Different Returns
        if(Array.isArray(this.props.curr_gift['asin'])){
            // Multiple gifts
            return (
                <Paper
                    style={{
                        height:320,
                        // display:"flex",
                        // flexDirection:"column",
                        // justifyContent:"space-between"
                    }}
                >
                    <div>
                    <form method="GET" action="https://www.amazon.com/gp/aws/cart/add.html">
                        <input type="hidden" name="AWSAccessKeyId" value="Access Key ID" />
                        <input type="hidden" name="AssociateTag" value="jamesdvance02-20" /> 
                        {this.props.curr_gift.asin.map((asin, idx)=>{
                            return(
                                <React.Fragment>
                                    <input type="hidden" name={"ASIN."+String(idx+1)} value={asin}/>
                                    <input type="hidden" name={"Quantity."+String(idx+1)}  value="1"/>
                                </React.Fragment>
                            )
                        })}
                            <input
                                style={{
                                    backgroundColor:"rgb(255,153,0)",
                                    width:"100%",
                                    fontSize:14
                                }}
                                type="submit"
                                value={"Buy All For "+this.props.curr_gift.price}
                            />
                        </form>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            flexWrap:"wrap",
                            alignItems:"center",
                            justifyContent:"center"
                        }}
                    >
                        {this.props.curr_gift.sm_img_link.map((img_link, idx)=>(

                            <div
                                key={img_link}
                            >
                                <Tooltip
                                    title={this.props.curr_gift.title[idx]}
                                >
                                <Typography
                                    noWrap={true}
                                    variant="caption"
                                    component="div"
                                    style={{
                                        color:"#808080",
                                        width:100
                                    }}
                                >
                                    {this.props.curr_gift.title[idx]}
                                </Typography>
                                </Tooltip>
                                <div  dangerouslySetInnerHTML={{__html:img_link}}></div>
                            </div>
                           ))}
                     </div>
                    <Rating name="read-only" value={this.props.curr_gift.stars} readOnly />
                </Paper>
            )
     
        }
        else{
            // Single Gifts
        return (
            <Paper
                style={{height:320}}
            >
                <div>
                <form method="GET" action="https://www.amazon.com/gp/aws/cart/add.html"
                    style={{
                        width:"100%",
                        margin:"auto",
                    }}
                >
                    <input type="hidden" name="AWSAccessKeyId" value="Access Key ID" />
                    <input type="hidden" name="AssociateTag" value="jamesdvance02-20" />
                    <input type="hidden"  name={"ASIN.1"} value={this.props.curr_gift.asin}/>
                    <input type="hidden" name={"Quantity.1"}  value="1"/>
                        <input
                            style={{
                                backgroundColor:"rgb(255,153,0)",
                                width:"100%",
                                fontSize:14
                            }}
                            type="submit"
                            value={"Get For $"+this.props.curr_gift.price}
                        />
                    </form>
                </div>
                <div
                >
                    <Typography
                        noWrap={true}
                        variant="caption"
                        component="div"
                        style={{
                            color:"#808080",
                            width:400
                        }}
                    >
                        {this.props.curr_gift.title}
                    </Typography>
                </div>
                <div  dangerouslySetInnerHTML={{__html:this.props.curr_gift.lg_img_link}}>

                </div>
                <Rating name="read-only" value={this.props.curr_gift.stars} readOnly />
            </Paper>
        )
      }
    }
  }
}
const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
        let curr_gift={}
        if(ownProps.loading_flg){
        }else{
            //console.log("attempting to read idx "+String(ownProps.idx_key))
            curr_gift=state.response[ownProps.idx_key][ownProps.gift_idx]
        }
        return ({
            curr_gift:curr_gift
        })
}

export default connect(mapStateToProps, )(GiftBox);

