import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Paper,
    Card,
    IconButton,
    Button,
    Typography
} from '@material-ui/core/';
import GiftBox from './giftbox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AddIcon from '@material-ui/icons/Add';
import {scrollItem,getAdditionalGifts} from '../actions';
import { typography } from '@material-ui/system';

class CategoryResult extends Component{
    
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    shouldComponentUpdate =(nextProps,nextState) =>{
        if((this.props.gift_idx==nextProps.gift_idx)&
            (this.props.loading_flg===nextProps.loading_flg)&
            (this.props.idx_key==nextProps.idx_key)
            ){
            return false
        }else{
            return true
        }
    }

    // componentDidMount(){
    //     //console.log("rendering "+this.props.category)
    // }

    handleForwardClick =()=>{
        this.props.scrollItem(this.props.idx_key,this.props.gift_idx,true)
    }

    handleBackClick =()=>{
        this.props.scrollItem(this.props.idx_key,this.props.gift_idx,false)
    }

    handlePlusClick =()=>{
        console.log("sending")
        this.props.getAdditionalGifts(this.props.category, this.props.idx_key)
    }

    render(){
        return (
            <div
                style={{
                    height:370,
                    width:400,
                    margin:"auto"
                }}
            >
                <div
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                        verticalAlign:"middle",
                        //backgroundColor:"yellow"
                    }}
                >
                <IconButton
                    //size
                    onClick={this.handleBackClick}
                    style={{
                        padding:0,
                        margin:0,
                        visibility:this.props.gift_idx==0?"hidden":""
                    }}
                >
                    <ArrowBackIosIcon
                            style={{
                                padding:0,
                                margin:0,
                                visibility:this.props.gift_idx==0?"hidden":""
                            }}
                    />
                    <Typography variant="h5">BACK</Typography>
                </IconButton>
                <div
                    style={{
                        display:"flex",
                        flexDirection:"row"
                    }}
                >
                    <Typography variant="h5"
                    style={{}}
                    >
                        {this.props.category}
                    </Typography>
                    <Typography style={{color:"rgb(255,153,0)", marginLeft:10}} variant="h6">
                        {this.props.num_items} +
                    </Typography>
                </div>
                <div
                    style={{
                    }}
                >

                    <IconButton
                        style={{padding:0,
                            margin:0,
                            display:this.props.gift_idx==(this.props.num_items-1)?"none":"",}}
                        onClick={this.handleForwardClick}
                    >
                        <Typography variant="h5">NEXT</Typography>
                        <ArrowForwardIosIcon
                            style={{
                                display:this.props.gift_idx==(this.props.num_items-1)?"none":"",
                                padding:0,
                                margin:0
                            }} 
                        />
                        {/* <ArrowForwardIcon
                            style={{
                                display:this.props.gift_idx==(this.props.num_items-1)?"none":"",
                                padding:0,
                                margin:0
                            }}
                        /> */}
                    </IconButton>
                    <IconButton
                        style={{
                                padding:0,
                                margin:0
                            }}
                        onClick={this.handlePlusClick}
                    >
                        <AddIcon
                            style={{
                                display:this.props.gift_idx!=(this.props.num_items-1)?"none":"",
                                padding:0,
                                margin:0
                            }}
                        />
                    </IconButton>
                </div>
                </div>
                <div>
                        <GiftBox
                            gift_idx={this.props.gift_idx}
                            idx_key={this.props.idx_key}
                            loading_flg={this.props.loading_flg}
                        />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    let gift_idx = state.cat_idx_list[ownProps.idx_key]
    let num_items = 0
    let loading_flg = state.loading_flg
    if(state.response[ownProps.idx_key]){
        num_items=state.response[ownProps.idx_key].length
    }
    else{
        loading_flg=true
    }
        return ({
            gift_idx:gift_idx,
            num_items:num_items,
            loading_flg:loading_flg
        })
}

export default connect(mapStateToProps, {scrollItem,getAdditionalGifts})(CategoryResult);
