import React, {Component} from 'react';
import {connect} from 'react-redux';
import CategorySelector from './category_selector';
import CategoryResult from './category_result'
import '../App.css';
import {
    InputAdornment,
    TextField,
    Typography,
    Checkbox,
    Grid,
    IconButton,

} from '@material-ui/core/';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import SyncIcon from '@material-ui/icons/Sync';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {changeCategory,getFullResponse,changeAmount, changeGroup} from '../actions';

class MainPage extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            mounting:true,
            toggle_button:"custom"
        } 
    }

    componentWillMount(){
        document.body.style.backgroundImage = "url('/background6.gif')";
        document.body.style.backgroundRepeat="repeat";
        document.body.style.backgroundAttachment="scroll";
        //send lambda warm-up
    }

    componentDidMount(){
        this.setState({mounting:false})
    }

    handleAmountChange(event){
        if((event.target.value>200)){
            alert("Please Enter An Amount Between 5 - 200")
        }else{
            this.props.changeAmount(event.target.value)
        }
    }

    handleCategoriesCheck(category, curr_val){
        let new_val = !curr_val
        if(!this.state.mounting){
            this.props.changeCategory(category,new_val)
        }
    }

    handleRefreshClick(){
        this.props.getFullResponse()
    }

    handleGiftGroupChange(value){
        this.setState({'toggle_button':value})
        console.log(value)
        this.props.changeGroup(value)
    }

    render(){
        return (
            <div
                style={{
                    margin: "auto",
                    textAlign:"center",
                }}
            >
                <div
                    style={{
                        maxWidth:500,
                        display:"flex",
                        alignContent:"center",
                        alignItems:"center",
                        justifyContent:"center",
                        //backgroundColor:"white",
                        color:"var(--text)",
                        margin:"auto",
                        marginTop:10
                    }}
                >
                    <Typography 
                        style={{
                            //color:"darkGrey"
                        }}
                        variant='h4'
                    >Your Budget</Typography>
                    <TextField
                        style={{width:110, marginLeft:10}}
                        variant='outlined'
                        defaultValue={this.props.amount}
                        label={"$5 - $200"}
                        InputProps={{
                            startAdornment:<InputAdornment style={{fontSize:38}} position="start">$</InputAdornment>,
                            style:{
                                textAlign:"center",
                                fontSize:38,
                                //color:"darkGrey"
                                backgroundColor:"white",
                            }
                        }}
                        onChange={(event)=>{this.handleAmountChange(event)}}
                    />
                    <IconButton
                        size="large"
                        onClick={()=>{this.handleRefreshClick()}}
                    >
                        <SyncIcon
                            fontSize="large"
                        />
                    </IconButton>
                </div>
                <div
                    style={{
                        marginTop:25
                    }}
                >
                    <div
                        style={{
                            display:"flex",
                            flexWrap:"wrap",
                            alignItems:"center",
                            justifyContent:"center",
                            background:"none"
                        }}         
                    >
                    <ToggleButtonGroup
                        fullWidth='false'
                        value={this.state.toggle_button}
                        size="small"
                        onChange={(event, value)=>{this.handleGiftGroupChange(value)}}
                        exclusive={true}
                    >
                        <ToggleButton  value="custom">Custom</ToggleButton>
                        <ToggleButton  value="dad">Dad</ToggleButton>
                        <ToggleButton  value="mom">Mom</ToggleButton>
                        <ToggleButton  value="girlfriend">Girlfriend</ToggleButton>
                        <ToggleButton  value="boyfriend">Boyfriend</ToggleButton>
                        <ToggleButton  value="white_elephant">White Elephant</ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                <div
                    style ={{
                        height:120,
                        maxWidth:990,
                        overflowY:"auto",
                        display:"grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                        gridGap:0,
                        margin:"auto",
                        textAlign:"left",
                        alignItems:"center",
                        border:"1px solid darkGrey",
                        backgroundColor:"white",
                    }}
                >
                {/* <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                        height:250,
                        width:600,
                        overflowY:"auto",
                        margin:"auto"
                    }}
                > */}

                    {this.props.categories.map((cat,idx)=>{
                        return(
                            // <Grid
                            //     item
                            //     style={{
                            //         width:150,
                            //         display:"flex",
                            //         verticalAlign:"bottom",
                            //     }}
                            //     key={cat+"_all"}
                            // >
                            <div
                            style={{
                                        width:150,
                                        display:"flex",
                                        verticalAlign:"bottom"
                                    }}
                                key={cat+"_all"}
                            >
                                <Checkbox
                                    checked={this.props.cat_incl[cat]}
                                    style={{padding:0,margin:0}}
                                    icon={<CheckBoxOutlineBlankIcon style={{color:"black)"}} />}
                                    checkedIcon={<CheckBoxIcon style={{color:"black"}} />}
                                    onChange={()=>{this.handleCategoriesCheck(cat,this.props.cat_incl[cat])}}
                                />
                                <Typography  
                                    variant="caption"
                                    display="block"
                                    style={{margin:0, padding:0, verticalAlign:"middle"}}
                                    gutterBottom={true}
                                    noWrap={true}
                                >
                                        {cat}
                                </Typography>
                                </div>
                            // </Grid>
                        )
                    })}
                {/* </Grid> */}
                </div>
                </div>
                <div
                    style={{
                        display:"grid",
                        gridTemplateColumns: "repeat(auto-fit,minmax(400px, 1fr))",
                        gridGap:"5",
                        margin:"auto",
                        marginTop:50
                    }}
                >
                {/* <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    // cellHeight={200}
                    // cols={3}
                > */}
                    {this.props.cat_incl_list.map((cat_incl, idx)=>{
                        return(
                            // <Grid
                            //     item
                            //     xs={1}
                            //     s={12}
                            //     md={4}
                            //     lg={4}
                            //     key={cat_incl}
                            //     style={{
                            //         width:450
                            //     }}
                            // >
                                <CategoryResult
                                    category={cat_incl}
                                    idx_key={idx}
                                    key={cat_incl}
                                />
                        //    </Grid>
                        )
                    })}
                 </div>
               {/* </Grid> */}
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    // console.log("re-rendering")
    // console.log(state.cat_incl_list.length)
         return ({
            amount:state.amount,
            categories:state.categories,
            cat_incl_list:state.cat_incl_list,
            cat_incl:state.cat_incl
        })
}

export default connect(mapStateToProps,{changeCategory,getFullResponse,changeAmount,changeGroup} )(MainPage);
