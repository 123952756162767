import {initialState} from '../initialState2'

export default function(state=initialState, action){
    switch(action.type){
        case 'CHANGING_AMOUNT':
            return {
                ...state, 
                amount:action.payload.amount
            }
        case 'CATEGORIES_CHANGE':
            return {
                ...state, 
                cat_incl:action.payload.cat_incl,
                cat_idx_list:action.payload.cat_idx_list,
                cat_incl_list:action.payload.cat_incl_list
            }
        case 'CATEGORIES_REMOVE':
            return{
                ...state,
                cat_incl:action.payload.cat_incl,
                cat_idx_list:action.payload.cat_idx_list,
                cat_incl_list:action.payload.cat_incl_list,
                response:action.payload.response
            }
        case 'NEW_RESULTS':
                return{
                    ...state,
                    response:action.payload.response
                }
        case 'ADD_GIFTS':
            return{
                ...state,
                response:action.payload.response,
                cat_idx_list:action.payload.cat_idx_list,
                loading_flg:false
            }
        case 'SCROLL':
            return{
                ...state,
                cat_idx_list:action.payload.cat_idx_list
            }
        case 'IS_LOADING':
            return{
                ...state,
                loading_flg:action.payload.loading_flg
            }
        default:
            return state;
    }
}